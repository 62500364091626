(<template>
  <div class="booking-buttons">
    <!-- <router-link v-if="showTranslationBtn"
                 :to="postTranslationLink"
                 :class="{'booking-buttons__btn--last': isInterpretationPage}"
                 replace
                 class="sk-btn sk-btn--default booking-buttons__btn">{{ $gettext('Order one more translation') }}</router-link> -->
    <router-link :to="postInterpretationLink"
                 replace
                 class="sk-btn sk-btn--default booking-buttons__btn">{{ $gettext('Book an interpreter') }}</router-link>
  </div>
</template>)

<script>
  import helpers from '@/helpers';

  export default {
    data() {
      return {
        organization: this.$route.params.organization || ''
      };
    },
    computed: {
      postTranslationLink() {
        return this.organization
          ? this.getOpenBookingLink('translation', this.organization)
          : this.$makeRoute({name: 'BuyerPostTranslationProject'});
      },
      postInterpretationLink() {
        return this.organization
          ? this.getOpenBookingLink('interpretation', this.organization)
          : this.$makeRoute({name: 'BuyerPostInterpretation'});
      },
      // showTranslationBtn() { return helpers.openBookingFieldConfig(this.organization, this).translationPage; },
      isInterpretationPage() { return this.$route.name === 'SuccessOpenBookingInterpretation'; }
    },
    methods: {
      getOpenBookingLink(type, organizationName) {
        if (this.$language.current === 'en') {
          return this.$makeRoute({
            name: type === 'interpretation' ? 'OpenBookingInterpretation' : 'OpenBookingTranslation',
            params: {organization: this.organization}
          });
        } else {
          return this.$makeRoute({
            name: type === 'interpretation' ? 'OpenBookingInterpretationNb' : 'OpenBookingTranslationNb',
            params: {organization: this.getOrganizationNameNb(organizationName)}
          });
        }
      },
      getOrganizationNameNb(organizationName) {
        return helpers.openBookingHelper.getTranslatedOpenBookingType(organizationName);
      }
    }
  };
</script>

<style scoped>
  .booking-buttons {
    display: flex;
    margin: 0 -10px;
  }

  .booking-buttons__btn {
    display: block;
    width: 100%;
    max-width: 220px;
    margin: 10px;
  }

  .booking-buttons__btn--last {
    order: 1;
  }

  @media (max-width: 550px) {
    .booking-buttons {
      flex-wrap: wrap;
    }

    .booking-buttons__btn {
      max-width: 100%;
      margin-top: 0;
    }
  }
</style>
