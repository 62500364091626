(<template>
  <landing-wrapper class="success-info">
    <div class="success-info__wrapper">
      <div class="success-info__section">
        <p class="success-info__title">{{ title }}</p>
        <p v-for="(description, index) in descriptions"
           :key="index"
           class="success-info__text"
           v-html="description"></p>
        <booking-buttons class="success-info__btns" />
      </div>
    </div>
  </landing-wrapper>
</template>)

<script>
  import LandingWrapper from '@/components/landing/LandingWrapper';
  import BookingButtons from '@/components/booking_components/BookingButtons';

  export default {
    components: {
      'landing-wrapper': LandingWrapper,
      'booking-buttons': BookingButtons
    },
    data() {
      return {
        organization: this.$route.params.organization
      };
    },
    computed: {
      title() {
        return this.$gettext('Thank you, we have received your booking request!');
      },
      descriptions() {
        return [
          this.$gettext('1. We have sent you an e-mail with the details of your request.'),
          this.organization === 'nav'
            ? this.$gettext('2. We have created an account for you on the platform. Having an account on our platform will let you see interpreters offers, accept offers, chat with interpreters before the assignment and get access to video room.')
            : this.$gettext('2. We are now contacting our interpreters. You will receive a confirmation email as soon as we have found the best interpreter for you.'),
          this.organization === 'nav' || this.organization === 'all'
            ? ''
            : this.$gettext('3. You can login to view all the assignments you and your department have posted on our platform. If you don\'t have a password, you can <button class="js-password-link success-info__link sk-link sk-link--default">visit this page to get a magic link</button>.')
        ];
      }
    },
    methods: {
      goToRestorePassword() {
        this.$router.push(this.$makeRoute({name: 'RestorePassword'}));
      }
    },
    created() {
      this.$store.commit('GlobalStore/stopProgress');
    },
    mounted() {
      document.documentElement.scrollTop = 0;
      const linkElement = document.querySelector('.js-password-link');
      if (linkElement) {
        linkElement.addEventListener('click', this.goToRestorePassword);
      }
    }
  };
</script>

<style scoped src="@/assets/tikktalk/css/open_booking_info.css"></style>

<style scoped src="@/assets/tikktalk/css/open_booking_success_info.css"></style>
