// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@assets/imgs/undefined_imgs/open-booking-info-step1.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@assets/imgs/undefined_imgs/open-booking-info-step2.svg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("@assets/imgs/undefined_imgs/open-booking-info-step3.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
exports.push([module.id, ".open-booking-info__title[data-v-2a186064] {\n  margin-bottom: 30px;\n  font-weight: bold;\n  font-size: 18px;\n  text-align: center;\n}\n.open-booking-info__text[data-v-2a186064] {\n  margin-bottom: 20px;\n}\n.open-booking-info__text[data-v-2a186064]:last-child {\n  margin-bottom: 0;\n}\n.open-booking-info__steps[data-v-2a186064] {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: justify;\n      -ms-flex-pack: justify;\n          justify-content: space-between;\n  margin-bottom: 30px;\n}\n.open-booking-info__step[data-v-2a186064] {\n  position: relative;\n  width: 100%;\n}\n.open-booking-info__step[data-v-2a186064]::before {\n  content: '';\n  display: block;\n  width: 100%;\n  height: 72px;\n  margin-bottom: 10px;\n  background-position: 50% 50%;\n  background-size: contain;\n  background-repeat: no-repeat;\n}\n.open-booking-info__step--register[data-v-2a186064]::before {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n.open-booking-info__step--order[data-v-2a186064]::before {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n.open-booking-info__step--perform[data-v-2a186064]::before {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n}\n.open-booking-info__step-text[data-v-2a186064] {\n  font-weight: bold;\n  text-align: center;\n}\n@media (max-width: 767px) {\n.open-booking-info__title[data-v-2a186064] {\n    margin-bottom: 20px;\n    line-height: initial;\n}\n.open-booking-info__steps[data-v-2a186064] {\n    margin-bottom: 20px;\n}\n.open-booking-info__step-text[data-v-2a186064] {\n    font-size: 10px;\n}\n.open-booking-info__step[data-v-2a186064]::before {\n    height: 60px !important;\n}\n}\n", ""]);
// Exports
module.exports = exports;
